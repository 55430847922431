<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="导出" @click="toExport"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="真实姓名" v-model="searchParam.realName" />
        <v-input label="用户昵称" v-model="searchParam.nickName" />
        <v-input label="用户手机" v-model="searchParam.mobile" />
        <v-input label="身份证件号码" v-model="searchParam.idCardNum" />
        <v-select
          clearable
          placeholder="请选择"
          :options="auditStatusOps"
          v-model="searchParam.realNameAuditStatus"
          @change="$refs.list.search()"
          label="审核状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="查看电话" type="text" @click="getDecPhone(scope.row)" />
        <v-button
          text="编辑"
          type="text"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          @click="toEdit(scope.row)"
        />
        <v-button text="发放积分" type="text" @click="toPostPoint(scope.row)" />
      </template>
    </v-list>
    <v-dialog
      title="提示"
      v-model="isdialog"
      :confirmLoading="confirmLoading"
      @confirm="confirm"
      cancelTxt="取消"
      sureTxt="发放"
    >
      <el-form>
        <el-form-item label="选择租户" v-if="tenantIdType.length != 0">
          <v-select
            clearable
            placeholder="请选择"
            :options="tenantIdType"
            v-model="form.tenantId"
          />
        </el-form-item>

        <el-form-item label="发放积分类型">
          <v-select
            clearable
            placeholder="请选择"
            :options="pointType"
            v-model="form.recordType"
          />
        </el-form-item>
        <el-form-item label="积分">
          <v-input v-model="form.pointCount" type="number" />
        </el-form-item>
      </el-form>
    </v-dialog>
    <v-dialog
      title="联系电话"
      v-model="dialogFormVisible"
      width="50%"
      @confirm="doCancel"
    >
      <p>{{ tel }}</p>
    </v-dialog>
  </div>
</template>

<script>
import {
  getUserListURL,
  getDecPhoneUrl,
  exportUserUrl,
  getPoints,
} from "./api.js";
import {
  auditStatusMap,
  setAuditStatusOps,
  realAuditStatus,
  sexMap,
  setSexOps,
  pointTypeMap,
  pointType,
  realAuditStatusMap,
} from "./map.js";
import { createHTMLVNode } from "@/utils/utils.js";

export default {
  name: "realNameAuditList",
  data() {
    return {
      form: {
        recordType: "",
        pointCount: "",
        tenantId: "",
      },
      dialogFormVisible: false,
      tel: "",
      pointType,
      isdialog: false,
      confirmLoading: false,
      searchParam: {
        realName: "",
        nickName: "",
        mobile: "",
        idCardNum: "",
        realNameAuditStatus: null,
      },
      userItem: {},
      exportParams: {
        url: exportUserUrl,
        params: {},
      },
      realAuditStatus,
      auditStatusOps: setAuditStatusOps(),
      setSexOps: setSexOps(),
      tableUrl: getUserListURL,
      headers: [
        {
          prop: "mobile",
          label: "手机号码",
        },
        {
          prop: "nickName",
          label: "用户昵称",
        },
        {
          prop: "realName",
          label: "真实姓名",
        },
        {
          prop: "gender",
          label: "性别",
          formatter: (row, prop) => {
            return sexMap[row[prop]] || "--";
          },
        },
        {
          prop: "idCardType",
          label: "身份证件类型",
        },
        {
          prop: "idCardNum",
          label: "身份证号码",
        },
        {
          prop: "relatedSpaceCount",
          label: "房号数目",
        },
        {
          prop: "realNameAuditStatus",
          label: "实名审核状态",
          formatter(row, prop) {
            // 0:待审核 1:审核通过 2:审核驳回
            return realAuditStatusMap[row["realNameAuditStatus"]] || "--";
          },
        },
        // {
        //   prop: "orderCount",
        //   label: "订单数量",
        // },
        {
          prop: "availableTokenPointStr",
          label: "积分",
          minWidth: "120",
          formatter: (row, prop) => {
            let oBtn = "";
            let arr = row.totalIntegralPointStr.split(",");
            if (row.totalIntegralPointStr) {
              let arr = row.availableTokenPointStr.split(",");
              arr.forEach((item) => {
                oBtn += `<p>${item}</p> `;
              });
            }
            return createHTMLVNode(this, oBtn, row, prop, {
              cb: (e, row) => {},
            });
          },
        },
        // {
        //   prop: "carportCount",
        //   label: "车位数量",
        // },
        // {
        //   prop: "carCount",
        //   label: "车辆数量",
        // },
        {
          prop: "createTime",
          label: "注册日期",
        },
      ],
    };
  },
  computed: {
    tenantIdType() {
      if (
        this.$store.state.app.userInfo &&
        this.$store.state.app.userInfo.containTenants
      ) {
        let arr = this.$store.state.app.userInfo.containTenants.map((item) => {
          item.label = item.tenantName;
          item.value = item.tenantId;
          return item;
        });
        return arr;
      } else {
        return [];
      }
    },

    createTime: {
      get() {
        return !this.searchParam.createTimeS && !this.searchParam.createTimeE
          ? []
          : [this.searchParam.createTimeS, this.searchParam.createTimeE];
      },
      set(val) {
        [this.searchParam.createTimeS, this.searchParam.createTimeE] = val || [
          "",
          "",
        ];
      },
    },
  },
  methods: {
    init() {
      this.form.tenantId = "";
      this.form.pointCount = "";
      this.form.recordType = "";
    },
    doCancel() {
      this.dialogFormVisible = false;
    },
    // 获取商品icon图
    async getDecPhone(row) {
      let params = {
        userId: row.userId,
      };
      let res = await this.$axios.get(getDecPhoneUrl, { params });
      if (res.code == 200) {
        this.tel = res.data;
        this.dialogFormVisible = true;
      }
    },
    confirm() {
      this.addPoints();
    },
    addPoints() {
      this.confirmLoading = true;
      if (this.tenantIdType.length != 0) {
        if (!this.form.tenantId) {
          this.$message("请选择租户");
          return;
        }
      }
      //按照当前登录的账号去发放--老蔡
      let params = {
        assetId: this.userItem.assetId,
        userId: this.userItem.userId,
        tenantId:
          this.tenantIdType.length != 0
            ? this.form.tenantId
            : this.$store.state.app.userInfo.tenantId,
        operationType: 1,
        tokenPoint: this.form.pointCount,
        recordType: this.form.recordType,
        remark: "手动赋分",
      };

      this.$axios
        .post(`${getPoints}`, params, {
          headers: { "Content-Type": "application/json; charset=UTF-8" },
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.isdialog = false;
            this.init();
          } else {
            let msg = res.msg || "获取积分失败";
            this.isdialog = false;
            this.$message(msg);
          }
          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        });
    },
    toPostPoint(item) {
      this.userItem = item;
      this.isdialog = true;
    },
    toAdd() {
      this.$router.push({
        name: "userManageForm",
        query: {
          isView: false,
        },
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "userManageForm",
        query: {
          id: row.userId,
          tenantId: row.tenantId,
        },
      });
    },
    toExport() {
      this.$axios
        .get("/gateway/blade-system/userManagement/exportUserList", {
          responseType: "blob",
        })
        .then((res) => {
          console.log(res, "res-----");
          // 得到请求到的数据后，对数据进行处理
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;charset=utf-8",
          }); // 创建一个类文件对象：Blob对象表示一个不可变的、原始数据的类文件对象
          console.log(res.headers, "res.headers");
          let fileName = decodeURI(res.headers["content-disposition"]); // 设置文件名称,decodeURI：可以对后端使用encodeURI() 函数编码过的 URI 进行解码。encodeURI() 是后端为了解决中文乱码问题
          if (fileName) {
            // 根据后端返回的数据处理文件名称
            fileName = fileName.substring(fileName.indexOf("=") + 1);
          }
          console.log(fileName, "fileName");
          const link = document.createElement("a"); // 创建一个a标签
          link.download = fileName; // 设置a标签的下载属性
          link.style.display = "none"; // 将a标签设置为隐藏
          link.href = URL.createObjectURL(blob); // 把之前处理好的地址赋给a标签的href
          document.body.appendChild(link); // 将a标签添加到body中
          link.click(); // 执行a标签的点击方法
          URL.revokeObjectURL(link.href); // 下载完成释放URL 对象
          document.body.removeChild(link); // 移除a标签
        });
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
